export class SeccionesLaboratorio {
    id: number;
    description: string;
    code?: string;
    isGrouped?: boolean;
    maximumPointsDay: number;
    maximumPointsMonth: number;
    status: boolean;

    isEdit?: boolean;

    constructor() {
      this.status = true;
    }
}