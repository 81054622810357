export class ProductStock {
  id: number;
  warehouseId: number;
  productld: number;
  idealStock: number;
  minimumStock: number;
  actualStock: number;
  productName: string;
  warehouseName: string;
  status: boolean;

  modifiedDate: any;
  createdDate: any;
  createdBy: number;
  modifiedBy: number;
}
