export class ProviderViewModel {
    id: number;
    providerOrManufacturer: string; // 'p' or 'm'
    code: string;
    name: string;
    isProvider: boolean;
    isManufacturer: boolean;
    nie: string;
    address: string;
    postalCode: string;
    townId?: any;
    telephone1: string;
    telephone2: string;
    fax: string;
    paymentMethodId?: any;
    expiryId?: number;
    email: string;
    web: string;
    notes: string;
    minPriceOrder?: number;
    shippingCost?: number;
    deliveryDates: string;
    shippingTypeId?: any;
    emailSendFormat: string;
    isActive: boolean;
    isReference?: boolean;
    isLabAcceptExternal?: boolean;
    createdDate: any;
    createdBy: number;
    modifiedDate: any;
    modifiedBy: number;
    paymentMethodName: string;
    shippingTypeName: string;
    townName: string;
    provinceName: string;
    countryName: string;
    imageName: string;
    imagePathUrl: string;
    paymentDueId: number;
    referenceNo: string;
    isExternalCenter: boolean;
    isMessenger: boolean;

    // properties for excel export
    status: number;
    searchText: string;
    providerManufacturerValue: number;

    providerManufacturerDetailDeliveryDatesList: any[];
}

