import { Injectable } from '@angular/core';
import { ApiService } from '../../common/services/api.service';
import { ProviderOfferData } from '../entities/provider-offer-data';
import API from './../../common/api.config.json';


@Injectable({
  providedIn: 'root'
})
export class ProviderManufacturerService {
  constructor(private _apiService: ApiService) { }

  GetProviderById(id: number) {
    return this._apiService.get(API.provider_manufacturer_details.getProviderManufacturerDetailsById + id).toPromise();
  }

  getProviderOfferDataListByProviderId(id: number) {
    return this._apiService.get(API.provider_offer.getProviderOfferDataListByProviderId + id, true)
      .map(list => list as ProviderOfferData[]).toPromise();
  }

  GetLatestProviderOfferByProviderProductId(providerId: number, productId: number) {
    return this._apiService.get(API.provider_offer.getLatestProviderOfferByProviderProductId + providerId + '/' + productId, true)
      .map(list => list as ProviderOfferData[]).toPromise();
  }
}
