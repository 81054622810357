export class ProductMovementModel {
  constructor() {
    this.movementTypeId = '0';
    this.warehouseId = '0';
    this.productId = '0';
  }
  movementTypeId: any;
  dateFrom: any;
  dateTo: any;
  providerId: any;
  lotId: any;
  warehouseId: any;
  productId: any;
  workTypeNo: any;
  isFromJob: number;

  dateFromView: any;
  dateToView: any;
}
