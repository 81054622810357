import { DoctorMetal } from '../../client/entities/doctor-metal';
export class Doctor {
  id: any;
  clientId?: any;
  code: string;
  name: string;
  registrationNumber: string;
  contactNo: string;
  note: string;
  isDefault?: boolean;
  status?: boolean;
  address: string;
  townId?: any;
  postalCode: string;
  metalId?: any;
  sex: string;
  clientTypeId?: any;
  languageId?: any;
  qualityTypeId?: any;

  doctorMetal: DoctorMetal[];
  showNote: boolean;
  doctorName: string;

  constructor() {
    this.status = false;
  }
}
