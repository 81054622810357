export class Task {
    id: any;
    code: string;
    name: string;
    day: string;
    hour: string;
    minute: string;
    pointsByDefault?: number;
    maxTaskPerDay?: number
    maxTaskPerMonth?: number;
    isRepeatSum: boolean;
    createdDate: any;
    status: boolean;
}