export class RequestData {
    page: number;
    pageSize: number;
    columns: string;
    filter: string;
    orderBy: string;
    active: number;

    constructor(page: number, pageSize: number, columns: string, filter: string, orderBy: string, active: number) {
        this.page = page;
        this.pageSize = pageSize;
        this.columns = columns;
        this.filter = filter;
        this.orderBy = orderBy;
        this.active = active;
    }
}
