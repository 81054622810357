import API from '../../common/api.config.json';
import { Injectable } from '@angular/core';
import { ApiService } from '../../common/services/api.service';
import { LableConfiguration } from '../entities/lable-configuration';

@Injectable({
  providedIn: 'root'
})
export class LableConfigurationService {

  constructor(private _apiService: ApiService) { }

  getLableConfigList() {
    try {
      return (this._apiService.get(API.lab_configuration.getLabelConfigList, true)
        .map(n => n as LableConfiguration[]).toPromise())
        .then(list => {
          localStorage.setItem('lableConfigs', JSON.stringify(list));
          return list;
        });
    } catch (error) { console.log(error); }
  }

  
}
