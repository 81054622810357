import { FormControl } from "@angular/forms";

export class Messengers {
  id: number;
  description: string;
  typeId: number;
  status: boolean;
  address: string;
  postalCode: string;
  townName: string;
  provinceName: string;
  contactPerson: string;
  townId: number;
  isProvider: any;
  providerId: any;

  type: string;
  isEdit: boolean;
  isDescriptionRequired: boolean;
  isAddressRequired: boolean;
  isPostalCodeRequired: boolean;
  townControl = new FormControl('');

  constructor() {
    this.status = true;
  }
}
