export class ClientDefaultPrintReport {
  id: number;
  clientId: number;
  areaKeyName: string;
  reportConfigValue: number;

  clientName: string;

  constructor() {
  }
}
