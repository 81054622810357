export class OrderEmailModel {
  orderFrom: string;
  orderTo: string;
  orderSubject: string;
  mailBody: string;
  attachmentName: string;
  orderId: number;
  providerId: number;
  password: string;
}
